import { TransactionState } from './providers'
import { ethers, FallbackProvider, JsonRpcProvider } from 'ethers'
import { erc20Abi } from 'viem'
import { getUniswapContractAddress } from '@/utils/uniswap/constants'
import { fromReadableAmountRoute } from '@/utils/uniswap/utils'
import { Token } from '@uniswap/sdk-core'

export async function getTokenApprovalRoute(
  version: 2 | 3 = 3,
  token: Token,
  provider: JsonRpcProvider | FallbackProvider,
  signer: ethers.Signer,
  approvalAmount: number
): Promise<TransactionState> {
  if (!provider) {
    return TransactionState.Failed
  }

  try {
    const tokenContract = new ethers.Contract(token.address, erc20Abi, provider)

    const chainId = (await provider.getNetwork()).chainId
    const swapRouterAddress = getUniswapContractAddress(
      chainId,
      version === 2 ? 'V2_SWAP_ROUTER' : 'V3_SWAP_ROUTER'
    )

    const tx = await tokenContract.approve.populateTransaction(
      swapRouterAddress,
      fromReadableAmountRoute(approvalAmount, token.decimals).toString()
    )

    const signedTx = await signer.sendTransaction(tx)
    const txReceipt = await provider.waitForTransaction(signedTx.hash)
    const confirmations = await txReceipt?.confirmations()

    if (confirmations && confirmations > 0) {
      return TransactionState.Sent
    } else {
      return TransactionState.Failed
    }
  } catch (e) {
    console.error(e)
    return TransactionState.Failed
  }
}
