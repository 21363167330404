// ** React Imports
import { ReactNode, createContext, useContext, useEffect, useState } from 'react';

// ** Hooks & utils
import { IExecDataProtector } from '@iexec/dataprotector';
import { IExecWeb3mail } from '@iexec/web3mail';
import { useAccount } from 'wagmi';
import { JsonRpcProvider } from 'ethers';
import { POLYGON_RPCS } from '@/utils/constants/rpcs';

/**
 * Iexec interface
 */
interface IExec {
  dataProtector: IExecDataProtector | null;
  web3Mail: IExecWeb3mail | null;
}
const iExecContext = createContext<IExec>({
  dataProtector: null
});

/**
 * Iexec context provider
 */
export function IexecContextProvider({
  children
}: {
  children: ReactNode;
}) {
  const [dataProtector, setDataProtector] = useState<IExecDataProtector | null>(null);
  const [web3Mail, setWeb3Mail] = useState<IExecWeb3mail | null>(null);
  const {
    address,
    isConnected
  } = useAccount();
  useEffect(() => {
    initSdk();
  }, [isConnected, address]);
  const initSdk = async () => {
    try {
      if (!isConnected || !address) return;
      const newProvider = new JsonRpcProvider(POLYGON_RPCS[0]);
      const initializedSdk = new IExecDataProtector(newProvider);
      const initializeWeb3Mail = new IExecWeb3mail(newProvider);
      setDataProtector(initializedSdk);
      setWeb3Mail(initializeWeb3Mail);
    } catch (error) {
      console.error('Error initializing iExec SDK:', error);
    }
  };
  const value: IExec = {
    dataProtector,
    web3Mail
  };
  return <iExecContext.Provider value={value} data-sentry-element="unknown" data-sentry-component="IexecContextProvider" data-sentry-source-file="iExec.tsx">{children}</iExecContext.Provider>;
}
export function useIexec() {
  return useContext(iExecContext);
}