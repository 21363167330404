import { Token, TradeType } from '@uniswap/sdk-core'
import { Trade } from '@uniswap/v3-sdk'
import { ethers, FallbackProvider, JsonRpcProvider } from 'ethers'

import { getUniswapContractAddress } from '@/utils/uniswap/constants'
import { erc20Abi } from 'viem'

export type TokenTrade = Trade<Token, Token, TradeType>

/**
 * Retrieves the current allowance amount for a token.
 * @param token - The token object containing its address and decimals.
 * @param provider - The JSON-RPC or fallback provider to interact with the blockchain.
 * @param userAddress - The address of the token owner.
 * @returns The current allowance amount as a BigNumber.
 */
export async function getTokenAllowance(
  version: 2 | 3 = 3,
  token: Token,
  provider: JsonRpcProvider | FallbackProvider,
  userAddress: string
): Promise<number | undefined> {
  if (!provider) return

  try {
    const tokenContract = new ethers.Contract(token.address, erc20Abi, provider)
    const chainId = (await provider.getNetwork()).chainId

    // Get the address of the swap router (or other spender) for the current chain
    const swapRouterAddress = getUniswapContractAddress(
      chainId,
      version === 2 ? 'SWAP_ROUTER' : 'V2_SWAP_ROUTER'
    )

    // Retrieve the allowance amount
    const allowance = await tokenContract.allowance(
      userAddress,
      swapRouterAddress
    )

    return Number(allowance)
  } catch (e) {
    console.error('Failed to fetch token allowance:', e)
  }
}
